import { Link } from 'gatsby'
import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'

// http://www.dktech.cz/?page_id=22

const Kontakty: FC = () => {
  return (
    <PageTemplate>
      <div className="topTitle">
        <h1><Link to="/kontakty">DKTech team – kontakty</Link></h1>
      </div>
      <p>Vedení serveru DKTech.cz se skládá z administrátorů (dah4k, 7framm, poker10, Rah-Ave). <strong>Administrátoři</strong> <span style={{ fontSize: '13px' }}>7framm</span> Dah4k poker10 <strong>GM</strong> Algy Bachar ——————————————————————————————————————– <br />
      Pokud budete cokoliv potřebovat řešit s GM nebo adminy, využijte pro to prosím naše <a href="https://dktech.cz/forum/index.php">fórum</a>&nbsp;, případně nás kontaktujte přímo ve hře.</p>
      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default Kontakty

export { Head } from '../components/defaultHtmlHead'